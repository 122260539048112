import $ from 'jquery';
import 'what-input';
import anime from 'animejs/lib/anime.es.js';
import Rellax from "rellax";
import ScrollOut from "scroll-out";
import objectFitImages from 'object-fit-images';
import { remove } from 'animejs';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
	document.body.classList.remove("resize-animation-stopper");
  }, 400);
});

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

jQuery(function ($) {
	azToggle();
	announcementSlider();
	cookieBanner();
	contentCardSlider();
	eventSlider();
	eventSlider2();
	discoverMoreSlider();
	featurePanelSlider();
	statsSlider();
	featureImgCols();
	gallerySlider();
	navSubNavToggle();
	navRovingTabindex();
	navToggle();
	objectFit();
	parallax();
	revealContent();
	revealContentCY();
	scrollReveal();
	titleAnimate();
	videoModal();
	accessibleNavigation();
	accessibleSubNavigation();
	convertActivePagination();
	paginationAttributes();
	subMenu();
	highlight();
	scroll();
	socialSlider();
	tabs();
	featureImg();
	mastheadSplit();


	function azToggle() {
		$( "#js-az-toggle" ).click(function() {
		  	$( ".az" ).toggleClass("is-active");
			  	var $this = $(this);
				$this.toggleClass("is-active");
		});
	}

	function announcementSlider () {
		const settings = {
			arrows: false,
			mobileFirst: true,
			infinite: false,
			responsive: [
				{
					breakpoint: 639,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1023,
					settings: "unslick"
				}
			]
		};

		const sl =  $('.announcements__list').slick(settings);

		$(window).on('resize', function() {
		   if( $(window).width() < 1023 &&  !sl.hasClass('slick-initialized')) {
				 $('.announcements__list').slick(settings);
			}
		});

		var windowWidth = $(window).width();

        if (windowWidth >= 1023) {
            $(document).ready(function(){
			    $('.announcements__card, .announcements__card a').removeAttr("tabindex");
			});
        }
	}

	function cookieBanner () {

		if (localStorage.getItem('cookieSeen') != 'shown') {
			$('.cookie-banner').delay(2000).addClass('is-active');
		};

		$('.cookie-banner .button').click(function() {
			$('.cookie-banner').fadeOut();
			localStorage.setItem('cookieSeen','shown');
		})
	}

	function contentCardSlider () {
		const settings = {
			arrows: false,
			dots: true,
			mobileFirst: true,
			infinite: false,
			responsive: [
				{
					breakpoint: 639,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 1023,
					settings: "unslick"
				}
			]
		};

		const sl =  $('.more__cards').slick(settings);

		$(".more__cards").each(function(){
			$("a").attr("role","");
			$(".more__cards").attr("role", "");
		});

		$(".more__cards .content-card").each(function(){
			$(this).attr('id', $(this).attr('aria-describedby'))
		});

		$(window).on('resize', function() {
		   if( $(window).width() < 1023 &&  !sl.hasClass('slick-initialized')) {
				 $('.more__cards').slick(settings);
			}
		});

		var windowWidth = $(window).width();

        if (windowWidth >= 1023) {
            $(document).ready(function(){
			    $('.content-card').removeAttr("tabindex aria-hidden");
			});
        }
	}

	function eventSlider () {
		const settings = {
			arrows: false,
			mobileFirst: true,
			autoplay: true,
			responsive: [
				{
					breakpoint: 1023,
					settings: "unslick"
				}
			]
		};

		const sl =  $('.event-card-group').slick(settings);

		$(window).on('resize', function() {
		   if( $(window).width() < 1023 &&  !sl.hasClass('slick-initialized')) {
				 $('.event-card-group').slick(settings);
			}
		});

		var windowWidth = $(window).width();

        if (windowWidth >= 1023) {
            $(document).ready(function(){
			    $('.event-card, .event-card a').removeAttr("tabindex");
			});
        }
	}

	function featurePanelSlider () {
		$('.feature-panel__slider').slick({
			fade: true,
			arrows: true,
			dots: true,
			autoplay: true,
		});
	}

	function statsSlider () {
		$('.stats-slider__slider').slick({
			slidesToShow: 1.72,
			slidesToScroll: 1,
			infinite: false,
			centerMode: false,
			arrows: false,
			dots: false,
			responsive: [
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1.72,
					}
				},
			]
		});
	}



	function gallerySlider () {

		$('.gallery').each(function(item) {
			if($(this).hasClass('gallery--arrows')) {
				$(this).slick({
					arrows: true,
					prevArrow:"<button class='slick-prev' aria-label='Slide prev'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					nextArrow:"<button class='slick-next' aria-label='Slide next'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					dots: false,
					variableWidth: true,
					appendArrows: $('.gallery__nav')[item]
				})
				$(this).slick('refresh')
			} else {
				$(this).slick({
					arrows: true,
					dots: false,
					variableWidth: true,
				})
			}
		})

		$('.gallery--arrows .slick-slide').each((index, item) =>{
			item.setAttribute('tabindex', -1)
		})
		$(".gallery--arrows").on("beforeChange", function (){
			$('.gallery--arrows .slick-slide').each((index, item) =>{
				item.setAttribute('tabindex', -1)
			})
		});
	}


	function eventSlider2 () {

		$('.event-slider__slider').each(function(item) {
			if($(this)) {
				$(this).slick({
					slidesToShow: 1.15,
					arrows: true,
					prevArrow:"<button class='slick-prev' aria-label='Slide Prev' ><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					nextArrow:"<button class='slick-next' aria-label='Slide Next' ><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					dots: false,
					appendArrows: $('.event-slider__nav')[item],
					slidesToScroll: 1,
					infinite: false,
					centerMode: false,
					accessibility: true,
					responsive: [
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 1.15,
							}
						},
					]
				})
			} 

			$('.event-slider__slide').each((index, item) => {
				item.setAttribute('tabindex', -1)
				if(item.classList.contains('slick-current')) {
					item.setAttribute('tabindex', 0)
				}	
			})

		})
	}

	function discoverMoreSlider () {

		$('.discover-more-slider__slider').each(function(item) {
			if($(this)) {

				const slides = $(this).find('.discover-more-slider__slide').length

				if(slides < 4) {
					$('.discover-more-slider__nav')[item].classList.add('hide')
				}

				onload = () => {
					if(document.querySelector('body').clientWidth < 640) {
						$('.discover-more-slider__nav')[item].classList.remove('hide')
					} else {
						if(slides < 4) {
							$('.discover-more-slider__nav')[item].classList.add('hide')
						}
					}
				}

				onresize = () => {
					if(document.querySelector('body').clientWidth < 640) {
						$('.discover-more-slider__nav')[item].classList.remove('hide')
					} else {
						if(slides < 4) {
							$('.discover-more-slider__nav')[item].classList.add('hide')
						}
					}
				};

				$(this).slick({
					slidesToShow: 3,
					arrows: true,
					prevArrow:"<button class='slick-prev' aria-label='Slide prev'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					nextArrow:"<button class='slick-next' aria-label='Slide next'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
					dots: false,
					appendArrows: $('.discover-more-slider__nav')[item],
					slidesToScroll: 1,
					infinite: false,
					centerMode: false,
					responsive: [
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
							}
						},
						{
							breakpoint: 1200,
							settings: {
								slidesToShow: 3,
							}
						},
					]
				})
			} 
		})

		$('.discover-more-slider__slide').each((index, item) =>{
			item.setAttribute('tabindex', -1)
		})
		$(".discover-more-slider").on("beforeChange", function (){
			$('.discover-more-slider__slide').each((index, item) =>{
				item.setAttribute('tabindex', -1)
			})
		});
	}

	function socialSlider () {


		const container = document.querySelector('.social-slider')

		if(container) {

			const feedId = container.querySelector('span.feed')
            const myRequest = new Request(`https://api.curator.io/v1/feeds/${feedId.dataset.feedId}/posts`);

			$('.social-slider__slider').each(function(item) {
				if($(this)) {	
					$(this).slick({
						slidesToShow: 2.65,
						arrows: true,
						prevArrow:"<button class='slick-prev' aria-label='Slide prev'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
						nextArrow:"<button class='slick-next' aria-label='Slide next'><svg width='16' height='24' viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.19751e-06 12L15.5294 0L15.5294 24L5.19751e-06 12Z' fill='#41A494'/></svg></button>",
						dots: false,
						appendArrows: $('.social-slider__nav')[item],
						slidesToScroll: 1,
						centerMode: false,
						responsive: [
							{
								breakpoint: 640,
								settings: {
									slidesToShow: 1,
								}
							},
							{
								breakpoint: 1200,
								settings: {
									slidesToShow: 2.65,
								}
							},
						]
					})
				} 
			})

			fetch(myRequest)
			.then((response) => response.json())
			.then((data) => {

			  data.posts.forEach((item, index) => {

				  if(index + 1 < 20) {
					  const listItem = document.createElement('div');
					  listItem.setAttribute('class', 'social-slider__slide')
					  listItem.appendChild(document.createElement('div')).setAttribute('class', 'content-card')

					  
					  const card = listItem.querySelector('.content-card')
					  
					  if(item.image) {
						card.appendChild(document.createElement('img')).setAttribute('src', item.image)
					  } else if(item.video) {
						card.appendChild(document.createElement('img')).setAttribute('src', item.image)
					  }
					  
					  const img = card.querySelector('img')
					  img.setAttribute('sizes', '(max-width: 640px) 100vw, (max-width: 1024px) 33vw, 338px')
					  img.setAttribute('alt', item.id)                        

					  if(item.network_name === "Instagram") {
						card.appendChild(document.createElement('i')).setAttribute('class', 'bi bi-instagram')
					  } else if(item.network_name === "Tiktok") {
						card.appendChild(document.createElement('i')).setAttribute('class', 'bi bi-tiktok')
					  } else if(item.network_name === "Twitter") {
						card.appendChild(document.createElement('i')).setAttribute('class', 'bi bi-twitter')
					  }
					  card.appendChild(document.createElement('a')).setAttribute('href', item.url)
					  
					  const a = card.querySelector('a')
					  a.setAttribute('target', '_blank')
					  a.setAttribute('rel', 'noopener noreferrer')
					  a.setAttribute('aria-label', item.url)
					  $('.social-slider__slider').slick('slickAdd',listItem);
				  }
			  })
			})
			.catch(console.error);
		}

	}

	function featureImgCols() {
		const container = document.querySelectorAll('.js-feature-img-cols')
		if(container) {
			container.forEach(div => {
				const cols = div.querySelectorAll('.feature-img-cols__image')
				if(cols.length === 2) {
					div.classList.add('feature-img-cols--2')
				} else {
					div.classList.add('feature-img-cols--3')
				}
			})
		}
	}

	function navSubNavToggle() {

		$('body').on('click', '.primary-menu button:not(.close-button)', function(e) {
			e.preventDefault();

			const featureMenuLink = $(this).next().find('.menu-feature a')
			const closeBtn = $(this).next().find('.close-button')
			
			$(this).parent().siblings().find('.is-active').removeClass('is-active');
			
			$(this).toggleClass('is-active');
			$(this).next().toggleClass('is-active');
			
			// check if top level nav items have close button if not then add
			if(!$(this).next().hasClass('no-bullet')) {
				if($(this).next().find('.close-button').length == 0) {
					$(this).next().prepend('<button class="close-button show-for-xlarge" tabindex="0"><span class="show-for-sr">Close Sub Nav</span></button>');
				}
			}
			
			if($(this).next().hasClass('is-active')) {
				featureMenuLink.attr('tabindex', 0);
				closeBtn.attr('tabindex', 0);
			} else {
				featureMenuLink.attr('tabindex', -1);
				closeBtn.attr('tabindex', -1);
			}

		})

		// We listen to the whole body incase the DOM has been manipluated after laoding
		$('body').on('click', '.close-button', function(e) {
			e.preventDefault();
			//Look for the direct parent in this case .sub-nav
			var parent = $(this).parent();
			//Remove class of parent to stop is being toggled (we dont use toggleClass) because the close button should only ever close
			$(parent).removeClass('is-active');
			//Remove class from direct previous sibling to parent (.sub-nav) this is the button that was clicked originaly to open the sub nav
			$(parent).prev().removeClass('is-active');
			//Remove button
			$(this).remove();
		})
	}

	function navRovingTabindex() {

		$('[aria-haspopup="true"]').keydown( function (e){
			if (e.keyCode == 13){
				let subnav = $(this).next();
				if(!$(this).hasClass('is-active')) {
					$.each( $(subnav).find('div > ul > li > a, ul > li > button, > li > a, > li > button'), function( index, item) {
						$(item).attr('tabindex', 0);
					});
				} else {
					$.each( $(subnav).find('div > ul > li > a, ul > li > button, > li > a, > li > button'), function( index, item) {
						$(item).attr('tabindex', -1);
					});
					$.each( $(subnav).find('.sub-nav-toggle'), function( index, item) {
						$(item).removeClass('is-active');
						if(!$(item).hasClass('is-active')) {
							$.each($(subnav).find('a'), function( index, a) {
								$(a).attr('tabindex', -1);
							})
						}
					});
				}
			}
		});

		document.onkeydown = function(evt) {
		    evt = evt || window.event;
		    if (evt.keyCode == 27) {
		        let item = document.activeElement;
		        let subnav = $(item).closest('[data-sub-nav]');
		        let focusElement = $(subnav).prev();

		        focusElement.focus();
		        subnav.removeClass('is-active');
				$.each( $('a, button', subnav), function( index, item) {
					$(item).attr('tabindex', '-1');
				});
		    }
		};

	}

	var mq = window.matchMedia( "(min-width: 1200px)" );
	const matches = $('body').innerWidth = mq.matches;
	if(matches) {
		const banner_links = $('.banner__links ul li a')
		const primary_links = $('.primary-menu ul li a')
		const primary_menu_button = $('.primary-menu ul li .sub-nav-btn')
		const subnav = $('.primary-menu ul li .sub-nav')
		const subnav__links = $('.primary-menu ul li .sub-nav a')

		banner_links.each(function() {
			$(this).attr('tabindex', 0)
		})
		primary_links.each(function() {
			$(this).attr('tabindex', 0)
		})

		primary_menu_button.attr('tabindex', 0)

		subnav.each(function() {
			if($(this).hasClass('is-active')) {
				subnav__links.each(function() {
					$(this).attr('tabindex', 0)
				})
			} else {
				subnav__links.each(function() {
					$(this).attr('tabindex', -1)
				})
			}
		})

	}

	function navToggle() {

		const menuToggle = $('.menu-toggle');
		const searchToggle = $('.search-toggle');
		const banner = $('.banner');
		const banner_menu = $('.banner__menu');

		$('body').on('click', '.menu-toggle', function(e) {
			e.preventDefault();

			if (searchToggle.next().hasClass('is-active'))  {
				searchToggle.next().removeClass('is-active');
				searchToggle.removeClass('is-active');
			}

			menuToggle.toggleClass('is-active');
			menuToggle.next().toggleClass('is-active');
			const primary_links = $('.primary-menu ul li a')
			const banner_links = $('.banner__links ul li a')

			var mq = window.matchMedia( "(max-width: 1200px)" );
			const matches = $('body').innerWidth = mq.matches;
			if(matches) {
				if(banner_menu.hasClass('is-active')) {
					primary_links.each(function() {
						$(this).attr('tabindex', 0)
					})
					banner_links.each(function() {
						$(this).attr('tabindex', 0)
					})
				}  else {
					primary_links.each(function() {
						$(this).attr('tabindex', -1)
					})
					banner_links.each(function() {
						$(this).attr('tabindex', -1)
					})
				}
			}


			if (menuToggle.next().hasClass('is-active')) {
				banner.addClass('menu-open');
				banner.removeClass('search-open');
			} else {
				banner.removeClass('menu-open');
				banner.removeClass('search-open');
			}

		});

		$('body').on('click', '.search-toggle', function(e) {
			e.preventDefault();

			if (menuToggle.next().hasClass('is-active'))  {
				menuToggle.next().removeClass('is-active');
				menuToggle.removeClass('is-active');
			}

			searchToggle.toggleClass('is-active');
			searchToggle.next().toggleClass('is-active');

			if (searchToggle.next().hasClass('is-active')) {
				banner.removeClass('menu-open');
				banner.addClass('search-open');
				$('.site-search input').attr('tabindex', 0);
			} else {
				banner.removeClass('menu-open');
				banner.removeClass('search-open');
				$('.site-search input').attr('tabindex', -1);
			}


		});

	}

	function objectFit () {
		objectFitImages();
	}

	function parallax() {
		if($(".parallax").length) {
			var rellax = new Rellax('.parallax', {
				center: true,
				speed: -1.5,
			});
		}
	}

	function revealContent() {
		$( ".button-reveal" ).click(function() {
		  	$( ".reveal-content" ).slideToggle( "fast" );
			  	var $this = $(this);
				$this.toggleClass("open");

				if ($this.hasClass("open")) {
					$this.html("Less");
				} else {
					$this.html("Read more");
				}
		});
	}

	function revealContentCY() {
		$( ".button-reveal-cy" ).click(function() {
		  	$( ".reveal-content" ).slideToggle( "fast" );
			  	var $this = $(this);
				$this.toggleClass("open");
				if ($this.hasClass("open")) {
					$this.html("Gweld llai");
				} else {
					$this.html("Darllenwch Mwy");
				}
		});
	}

	function scrollReveal() {
		ScrollOut({
			once: true,
		});
	}

	function titleAnimate() {
		if($(".title-animate").length){
       		// Wrap every word in a span
			var textWrapper = document.querySelector('.title-animate');
			textWrapper.innerHTML = textWrapper.textContent.replace(/\w+/g, "<span class='word'>$&</span>");

			anime.timeline()
			.add({
				targets: ".title-animate .word",
				translateY: [70,0],
				translateZ: 0,
				opacity: [0,1],
				easing: "easeOutExpo",
				visibility: "visible",
				duration: 1400,
				delay: (el, i) => 300 + 200 * i
			});
        }
	}

	function videoModal() {
		$('[data-video-id]').on('click', function (e) {
			var targetModal = $('#' + $(this).data('modal'));
			targetModal.find('iframe').attr("src", "https://www.youtube.com/embed/" +  $(this).data("video-id") + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0');
			targetModal.trigger('open');
			e.preventDefault();
		});

		$(document).on('closed.zf.reveal', '#video-modal[data-reveal]', function() {
			$(this).find('iframe').attr("src", "");
		});
	}
});

	function accessibleNavigation() {
		$('.sub-list-item').find('a').attr("tabindex", "-1").attr("role", "menuitem");
		$('.lan-switcher').find('a').attr("tabindex", "-1").attr("role", "menuitem");
	}

	function accessibleSubNavigation() {
		$('.sub-menu-item').find('a').attr("role", "menuitem");
		$('.multilevel-linkul-0').attr("role", "menubar");
		$('.multilevel-linkul-0').find('li').attr("role", "none");
	}

	function breadcrumbAttributes() {
		$('.breadcrumb').find('a').wrap('<li class="breadcrumb__item"></li>');
		$('.breadcrumb').find('li').last().addClass("active").attr("aria-current", "page");
		$('.breadcrumb').find('a').last().addClass("no-after");
	}

	function subMenu() {
			var $submenu = $('.secondary-menu');
			if ($submenu.find("ul").length == 0) {
				$('.secondary-menu').remove();
			}
		}
	  
	  function convertActivePagination() {
		$('span[class^=currentpage]').wrap('<a href="#"></a>').parent().addClass("is-active");
	  }

	  function paginationAttributes() {
		if ($('ul').hasClass('pagination')) {
		$('.pagination').find('a').wrap('<li></li>');
		$('.pagination').find('a').addClass("clear button");
		$('a:contains("<<")').unwrap().remove();
		$('a:contains(">>")').unwrap().remove();
		$('a:contains("<")').text('Previous').removeClass("clear").addClass("hollow");
		$('a:contains(">")').text('Next').removeClass("clear").addClass("hollow");
			if ($('.pagination a:first').hasClass('is-active')) {
				$(".pagination").prepend("<li></li>");
			} 
			if ($('.pagination a:last').hasClass('is-active')) {
				$(".pagination").append("<li></li>");
			} 
		}
	}

	function highlight() {
		const item = document.querySelectorAll('.js-highlight')

		if(item) {
		  item.forEach(item => {
			const string = `${item.innerHTML}`;
			const highlight = item.dataset.highlight;
			if(highlight) {
			   	const newString = string.replace(highlight, '<strong>' + highlight + '</strong>');
				item.innerHTML = newString
			 }
		  })
		}
	}

	function scroll() {
		const btn = document.querySelector('.js-page-scroll')
		const masthead = document.querySelector('.js-masthead')

		if(btn) {
		  btn.addEventListener('click', () => {
			if(masthead) {
				window.scroll({
					top: masthead.clientHeight,
					behavior: "smooth",
				});
			}
		  })
		}
	}

	function tabs() {
		$('.tabs-title a').each((index, item) => {
			item.setAttribute('tabindex', 0)
		})
	}

	function featureImg() {
		const container = document.querySelectorAll('.feature-img')
		if(container) {
			container.forEach(section => {
				const content = section.querySelector('.feature-img__content')
				const text = section.querySelector('.feature-img__text')

				let mql = window.matchMedia('(min-width: 1440px)').matches;
				console.log(mql)
				if(mql) {
					text.style.width = content.clientWidth * 1.35 + 'px';
				}
				
				window.addEventListener("resize", function() {
					if (window.innerWidth > 1440) {
						text.style.width = content.clientWidth * 1.35 + 'px';
						console.log(text.style.width)
					} else if(window.innerWidth > 640) {
						text.style.width = '50%';
					} else {
						text.style.width = 'calc(82% + 0.938rem)';
					}
				});

			})
		}
	}

	function mastheadSplit() {
		const container = document.querySelector('.masthead-split');
		const banner = document.querySelector('.banner')

		if(container) {
			console.log(container.classList[1])
			if(container.classList[1] && container.classList[1] !== 'masthead-search') {
				banner.classList.add(container.classList[1])
			}
		}
	}


$(document).foundation();